import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { EDITABLE_DOCUMENT_PROPS } from "nlib/pages/DocumentsPage/lib/EditDocument";
import { INPUT_FIELD_NAMES, INPUT_FIELD_NAMES_ARRAY } from "nlib/common/ContactForm/constants";
import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData
} from "selectors/businesses";
import { getDocumentsData } from "selectors/documents";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AwaitingButton from "nlib/common/AwaitingButton";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import DocumentsActions from "actions/DocumentsActions";
import FormContext from "contexts/FormContext";
import MainApiActions from "actions/MainApiActions";
import React, { useCallback, useContext, useMemo } from "react";
import Utils from "utils/Utils";
import ValidationSchemes from "const/ValidationSchemes";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const ADDRESS_FIELDS_TO_COMPARE = INPUT_FIELD_NAMES_ARRAY.filter((field) => field !== INPUT_FIELD_NAMES.TYPE);

const {
  DOCUMENT_TYPES: { RECEIPT },
  STATUSES: { TO_REPORT, EXCLUDED, EXPORTED, TO_EXTRACT, TO_REVIEW }
} = DataConstants;

const DOCUMENT_FROZEN_STATUSES = [TO_REPORT, EXCLUDED, EXPORTED];

const DEFAULTS = { issueDate: "", dueDate: "", originalDocumentId: "" };

const Header = ({ disabled, documentData = {}, resetDuplicatedDocumentsIds, openDocument, onClose }) => {
  const {
    id: documentId,
    address,
    vendorId,
    createdAt,
    attachment = {},
    duplicatedDocumentsIds,
    paidTransactions = [],
    originalDocumentId = ""
  } = documentData;

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const documentsData = useSelector(getDocumentsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const { countryCode } = useSelector(getActiveOrganization);

  const {
    extraData: { integrationServiceConnected } = {}
  } = useSelector(getSelectedBusinessData);

  const { values, onSubmit } = useContext(FormContext);

  const closedBookDate = useMemo(() => {
    return selectedBusinessBookCloseDate
      && moment.utc(values.issueDate).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate));
  }, [values.issueDate, selectedBusinessBookCloseDate]);

  const usCountry = countryCode === Countries.US;

  const extractedDocumentsData = documentsData.filter(({ status }) => status !== TO_EXTRACT);

  const currentDocumentIndex = extractedDocumentsData.findIndex(({ id }) => id === documentData.id);

  const duplicatedDocument = !!(duplicatedDocumentsIds && duplicatedDocumentsIds.length);

  const businessName = (vendorId && address && address.name) || "";

  const documentFrozen = documentData && DOCUMENT_FROZEN_STATUSES.includes(documentData.status);

  const createdBy = useMemo(() => {
    const found = Utils.arrayFindById(extractedDocumentsData, documentData.id);

    return (found && found.createdBy) || {};
  }, [documentData.id, extractedDocumentsData]);

  const hasChanges = useMemo(() => {
    const { address: initialAddress, ...restDocument } = Utils.getProps(documentData, EDITABLE_DOCUMENT_PROPS, DEFAULTS);

    const { address: editedAddress, ...restValues } = values;

    if (!Utils.deepEqual(restDocument, restValues)) {
      return true;
    }

    return !values.vendorId && !Utils.deepEqual(
      Utils.getProps(initialAddress, ADDRESS_FIELDS_TO_COMPARE),
      Utils.getProps(editedAddress, ADDRESS_FIELDS_TO_COMPARE)
    );
  }, [documentData, values]);

  const closeDocument = useCallback(async(nextDocumentId = null) => {
    if (hasChanges) {
      const modalResult = await showCommonModal({
        text: messages.unsavedChangesWarning,
        headerText: uiTexts.warning,
        confirm: true,
        okButtonText: uiTexts.yes,
        cancelButtonText: uiTexts.no
      });

      if (modalResult) {
        await onSubmit();
      }
      onClose(modalResult);
    } else if (resetDuplicatedDocumentsIds) {
      if (!nextDocumentId) {
        await dispatch(
          DocumentsActions.editDocument(documentId, { duplicatedDocumentsIds: [] }, false, true)
        );
      }
      onClose(!nextDocumentId);
    } else {
      onClose();
    }
    if (nextDocumentId) openDocument(nextDocumentId);
  }, [
    hasChanges,
    messages,
    uiTexts,
    showCommonModal,
    dispatch,
    documentId,
    resetDuplicatedDocumentsIds,
    onSubmit,
    openDocument,
    onClose
  ]);

  const switchDocument = useCallback(async(index) => {
    const nextDocument = extractedDocumentsData[currentDocumentIndex + index];

    if (!nextDocument) return;

    await closeDocument(nextDocument.id);
  }, [currentDocumentIndex, extractedDocumentsData, closeDocument]);

  const handleCloseClick = useCallback(() => {
    closeDocument();
  }, [closeDocument]);

  const handleFormSubmit = useCallback(async(params) => {
    const nextDocument = extractedDocumentsData[currentDocumentIndex + 1];

    const result = await onSubmit(params);

    if (result && nextDocument) openDocument(nextDocument.id);
  }, [currentDocumentIndex, extractedDocumentsData, onSubmit, openDocument]);

  const handleToReportClick = useCallback(() => {
    handleFormSubmit({ toReport: true });
  }, [handleFormSubmit]);

  const handleExcludeClick = useCallback(() => {
    handleFormSubmit({ toExclude: true });
  }, [handleFormSubmit]);

  const handlePrevDocumentButtonClick = useCallback(() => {
    switchDocument(-1);
  }, [switchDocument]);

  const handleNextDocumentButtonClick = useCallback(() => {
    switchDocument(1);
  }, [switchDocument]);

  const handleDownloadClick = useCallback(async() => {
    const link = await dispatch(MainApiActions.fetchAttachmentUrl(attachment, true));

    if (!link) return;

    Utils.downloadContent(link);
  }, [attachment, dispatch]);

  const disableSaveButton = disabled || !hasChanges;

  const disableToReportButton = useMemo(() => {
    if (businessUser || disabled || !integrationServiceConnected || documentFrozen) return true;

    const { paid, issueDate, paymentDate } = values;

    if (
      (xeroBusiness || zohoBusiness) && paid && !paidTransactions.length
        && (!paymentDate || (issueDate && moment.utc(issueDate).isAfter(moment.utc(paymentDate))))) {
      return true;
    }

    const validationResult = ValidationSchemes.ACCOUNTANT_DOCUMENT_LINE_ITEM_VALIDATION_SCHEMA.isValidSync(values, {
      context: {
        ...values,
        xeroBusiness,
        taxRequired: quickBooksBusiness && !usCountry
          && values.lineItems.some(({ taxRate = {} }) => taxRate && taxRate.id)
      }
    });

    if (!validationResult) return true;

    const { DOCUMENT_VALIDATION_SCHEMA, RECEIPT_VALIDATION_SCHEMA } = ValidationSchemes;

    const validationSchema = values.type === RECEIPT
      ? RECEIPT_VALIDATION_SCHEMA
      : DOCUMENT_VALIDATION_SCHEMA;

    if (!validationSchema.isValidSync(values, { context: { paid: values.paid } })) return true;

    return false;
  }, [
    disabled,
    documentFrozen,
    integrationServiceConnected,
    businessUser,
    paidTransactions.length,
    values,
    xeroBusiness,
    zohoBusiness,
    quickBooksBusiness,
    usCountry
  ]);

  const { fullName, email } = createdBy;

  const createdByText = (fullName ? fullName + (fullName === email ? "" : ` <${email}>`) : email)
    || uiTexts.unknown.toLowerCase();

  const createdAtText = moment(createdAt).format(Constants.DATETIME_FORMATS.DATETIME_TEXT);

  return (
    <div className={Css.header} id="edit-document-header">
      <div className={Css.info}>
        <div className={Css.title}>
          {duplicatedDocument
            ? <Icons.Copy className={Css.warning} />
            : <Icons.FileText />}
          <b className={Css.documentId}>
            {originalDocumentId ? `#${originalDocumentId}` : ""}
          </b>
          <span className={businessName ? Css.muted : ""}>
            {businessName
              ? `${originalDocumentId ? " | " : ""}${businessName}`
              : `${originalDocumentId ? " — " : ""}${uiTexts.editDocument}`}
          </span>
        </div>
        <div className={Css.subtitle} title={`${uiTexts.uploadedBy}: ${createdByText}, ${createdAtText}`}>
          <span>{`${uiTexts.uploadedBy}: `}</span>
          <b>{createdByText}</b>
          <span>{`, ${createdAtText}`}</span>
        </div>
      </div>
      <AwaitingButton
        large outline
        disabled={disabled}
        icon={Icons.Download}
        className={Css.download}
        title={uiTexts.download}
        onClick={handleDownloadClick}>
        {uiTexts.download}
      </AwaitingButton>
      {!documentFrozen && !businessUser && documentData.status !== EXCLUDED && (
        <Button
          large outline
          disabled={disabled || documentFrozen}
          onClick={handleExcludeClick}>
          {uiTexts.exclude}
        </Button>
      )}
      {(extractedDocumentsData.length > 1) && (
        <div className={Css.switchButtons}>
          <Button
            large outline
            disabled={currentDocumentIndex === 0 || disabled}
            onClick={handlePrevDocumentButtonClick}>
            <Icons.ArrowLeft />
          </Button>
          <div className={Css.counter}>
            {`${currentDocumentIndex + 1}/${extractedDocumentsData.length}`}
          </div>
          <Button
            large outline
            disabled={currentDocumentIndex === extractedDocumentsData.length - 1 || disabled}
            onClick={handleNextDocumentButtonClick}>
            <span>{uiTexts.nextDocument}</span>
            <Icons.ArrowRight />
          </Button>
        </div>
      )}
      <div className={Css.actions}>
        <Button
          large outline
          disabled={disabled}
          className={Css.closeButton}
          icon={Icons.X}
          onClick={handleCloseClick}>{uiTexts.close}</Button>
        {!documentFrozen && (
          <Button
            large primary
            disabled={disableSaveButton}
            onClick={handleFormSubmit}>
            {uiTexts.saveAndClose}
          </Button>
        )}
        {!businessUser && (documentData.status === TO_REVIEW) && !documentFrozen && (
          <Button
            large
            outline={closedBookDate}
            success={!closedBookDate}
            disabled={disableToReportButton}
            className={closedBookDate ? Css.warning : undefined}
            icon={!documentFrozen && closedBookDate && Icons.Warning}
            onClick={handleToReportClick}>
            {uiTexts.approveAndClose}
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(Header);
