import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { checkIsBusinessUser } from "selectors/user";
import { checkTransactionsFetching } from "selectors/transactions";
import { getActiveOrganization } from "selectors/organizations";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getCurrentZohoOrganizationId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { getDocumentsStats, getLockedDocuments } from "selectors/documents";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import ActionsCell from "./lib/ActionsCell";
import AddressCell from "./lib/AddressCell";
import AmountCell from "./lib/AmountCell";
import Async from "utils/Async";
import BulkActions from "./lib/BulkActions";
import CategoriesCell from "./lib/CategoriesCell";
import Checkbox from "nlib/ui/Checkbox";
import CommentCell from "./lib/CommentCell";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import DocumentIdCell from "./lib/DocumentIdCell";
import DocumentsActions from "actions/DocumentsActions";
import DocumentsPopup from "nlib/common/DocumentsPopup";
import EmptyState from "./lib/EmptyState";
import Filters from "./lib/Filters";
import IssueDateCell from "./lib/IssueDateCell";
import LineItemsCell from "./lib/LineItemsCell";
import Pagination from "nlib/ui/Pagination";
import PaymentAccountCell from "./lib/PaymentAccountCell";
import PreviewCell from "./lib/PreviewCell";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectPageSize from "nlib/ui/SelectPageSize";
import StatusCell from "./lib/StatusCell";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import TransactionsCell from "./lib/TransactionsCell";
import TypeCell from "./lib/TypeCell";
import UiActions from "actions/UiActions";
import UploadedByCell from "./lib/UploadedByCell";
import Utils from "utils/Utils";
import ValidationSchemes from "const/ValidationSchemes";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const { STATUSES } = DataConstants;

const checkDocumentDataValidityAsync = async(documentData, { quickBooksBusiness, usCountry, xeroBusiness }) => {
  const { enabledButtons, selectedEditTab, files, ...restData } = documentData;

  const { DOCUMENT_VALIDATION_SCHEMA, RECEIPT_VALIDATION_SCHEMA } = ValidationSchemes;

  const validationSchema = restData.type === DataConstants.DOCUMENT_TYPES.RECEIPT
    ? RECEIPT_VALIDATION_SCHEMA
    : DOCUMENT_VALIDATION_SCHEMA;

  if (await validationSchema.isValid(restData, { context: restData })) {
    const validationResult = await ValidationSchemes.ACCOUNTANT_DOCUMENT_LINE_ITEM_VALIDATION_SCHEMA.isValid(restData, {
      context: {
        ...restData,
        xeroBusiness,
        taxRequired: quickBooksBusiness && !usCountry
          && restData.lineItems.some(({ taxRate = {} }) => taxRate && taxRate.id)
      }
    });

    if (validationResult) return true;
  }

  return false;
};

const DocumentsTable = (props) => {
  const {
    simplifyLayout,
    fetchingData,
    documentsData,
    openDocument,
    refetchDocuments,
    onPairTransactionsLinkClick
  } = props;

  const dispatch = useDispatch();

  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const documentsStats = useSelector(getDocumentsStats);

  const transactionsFetching = useSelector(checkTransactionsFetching);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const {
    extraData: { integrationServiceConnected } = {},
    settings: {
      advancedDocumentsWorkflow,
      recognizeLineItems
    } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const { countryCode } = useSelector(getActiveOrganization);

  const lockedDocuments = useSelector(getLockedDocuments);

  const closedBookDateDocumentIds = useMemo(() => {
    return selectedBusinessBookCloseDate ? documentsData.filter(({ issueDate }) => {
      return issueDate && moment.utc(issueDate).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate));
    }).map(({ id }) => id) : [];
  }, [documentsData, selectedBusinessBookCloseDate]);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [documentsReadyToApproveIds, setDocumentsReadyToApproveIds] = useState([]);

  const czCountry = activeOrganization.countryCode === Countries.CZ;

  const { page = 1, pageSize = Constants.TABLE_PAGE_SIZE } = envVars;

  const usCountry = countryCode === Countries.US;

  const selectableItemIds = useMemo(() => {
    return documentsData.filter((documentData) => documentData.status !== STATUSES.TO_EXTRACT).map(({ id }) => id);
  }, [documentsData]);

  const allItemsSelected = useMemo(() => {
    return !!selectableItemIds.length && selectableItemIds.every((id) => selectedDocuments.includes(id));
  }, [selectableItemIds, selectedDocuments]);

  const showCategorySelector = useMemo(() => {
    return !recognizeLineItems && documentsData
      .some(({ lineItems, status }) => lineItems?.length === 1 && status === STATUSES.TO_REVIEW);
  }, [documentsData, recognizeLineItems]);

  const bulkDocumentApprove = useCallback(async(documentsIds) => {
    const documentsReadyToApprove = documentsData.filter(({ id }) => documentsIds.includes(id));

    const rejectedDocuments = documentsReadyToApprove.filter(({ id }) => closedBookDateDocumentIds.includes(id));

    if (rejectedDocuments.length) {
      const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

      dispatch(
        UiActions.showModal(
          Utils.replaceTextVars(messages.closeBookWarningForDocs, {
            date: closedBookTimeText,
            service: selectedBusinessIntegrationServiceData.label
          }),
          uiTexts.warning
        )
      );

      return;
    }

    const ids = documentsReadyToApprove.map(({ id }) => id);

    await dispatch(DocumentsActions.bulkDocumentsUpdate({
      ids,
      data: { status: DataConstants.STATUSES.TO_REPORT }
    }));

    refetchDocuments();
  }, [
    dispatch,
    documentsData,
    messages,
    selectedBusinessBookCloseDate,
    closedBookDateDocumentIds,
    selectedBusinessIntegrationServiceData,
    refetchDocuments,
    uiTexts
  ]);

  const handleSelectedChange = useCallback((value, event) => {
    const { id } = event.target.dataset;

    setSelectedDocuments((prev) => value ? [...prev, id] : prev.filter((el) => el !== id));
  }, []);

  const handleSelectAllChange = useCallback(() => {
    setSelectedDocuments(
      allItemsSelected ? [] : selectableItemIds
    );
  }, [allItemsSelected, selectableItemIds]);

  const handleTableSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  const handleBulkActionsCancel = useCallback(() => {
    setSelectedDocuments([]);
  }, []);

  const handleBulkActionsEdit = useCallback(async(data = {}) => {
    const {
      status,
      address,
      category,
      item,
      class: classValue,
      location,
      project,
      taxRate,
      tags,
      serviceAccountId,
      remove
    } = data;

    const allowedToChangeIds = selectedDocuments.filter((id) => selectableItemIds.includes(id));

    setSelectedDocuments([]);

    const results = allowedToChangeIds.length ? await Promise.all(
      [
        address?.id && { vendorId: address.id },
        category?.code && { category, tags },
        classValue?.id && { class: classValue },
        location?.id && { location },
        project?.id && { project },
        taxRate?.id && { taxRate },
        item?.id && { item },
        serviceAccountId && { serviceAccountId },
        status && { status },
        remove && {}
      ]
        .filter(Boolean)
        .map((update, index, array) => {
          return dispatch(
            DocumentsActions.bulkDocumentsUpdate({
              ids: allowedToChangeIds.filter((id) => {
                if (!update.item || !xeroBusiness) return true;

                const { salesItem, purchaseItem } = update.item;

                const documentData = Utils.arrayFindById(documentsData, id);

                return documentData?.paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY ? purchaseItem : salesItem;
              }),
              data: update,
              silently: (index + 1) < array.length
            })
          );
        })
    ) : null;

    if (results && Math.max(...results.map((result) => result && result.successCount), 0)) {
      refetchDocuments();
    }
  }, [selectedDocuments, selectableItemIds, dispatch, xeroBusiness, documentsData, refetchDocuments]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    setEnvVars({ pageSize: nextPageSize });
  }, [setEnvVars]);

  const handleRowClick = useCallback((event) => {
    openDocument(event.currentTarget.dataset.id);
  }, [openDocument]);

  const handleDocumentApprove = useCallback((documentId) => {
    bulkDocumentApprove([documentId]);
  }, [bulkDocumentApprove]);

  const handlePopupSubmit = useCallback(() => {
    bulkDocumentApprove(documentsReadyToApproveIds.filter((id) => !closedBookDateDocumentIds.includes(id)));
  }, [documentsReadyToApproveIds, closedBookDateDocumentIds, bulkDocumentApprove]);

  const handleEditDocument = useCallback(async(documentId, payload) => {
    dispatch(DocumentsActions.lockDocument(documentId));
    await dispatch(DocumentsActions.editDocument(documentId, payload, false, false, false, true));
    dispatch(DocumentsActions.unlockDocument(documentId));
  }, [dispatch]);

  useEffect(() => {
    setSelectedDocuments((prevState) => {
      return prevState.filter((id) => Utils.arrayFindById(documentsData, id));
    });
  }, [documentsData]);

  useEffect(() => {
    if (transactionsFetching || documentsData.length || !documentsStats.current) {
      return;
    }

    const lastPage = Math.ceil(documentsStats.current / pageSize);

    setEnvVars({ page: (+page > lastPage) ? lastPage : page });
  }, [
    page,
    pageSize,
    transactionsFetching,
    documentsData.length,
    documentsStats,
    setEnvVars
  ]);

  useEffect(() => {
    if (businessUser || !integrationServiceConnected) return;

    (async() => {
      const result = (await Async.runInSequence(documentsData.map((documentData) => {
        return async() => {
          return (documentData.status === DataConstants.STATUSES.TO_REVIEW)
            && (await checkDocumentDataValidityAsync(documentData, { quickBooksBusiness, usCountry, xeroBusiness }))
            && documentData.id;
        };
      }))).filter(Boolean);

      setDocumentsReadyToApproveIds((prevState) => {
        return prevState.join(",") === result.join(",") ? prevState : result;
      });
    })();
  }, [
    businessUser,
    documentsData,
    integrationServiceConnected,
    closedBookDateDocumentIds,
    quickBooksBusiness,
    xeroBusiness,
    usCountry
  ]);

  const showCommentCell = !businessUser || documentsData.some(({ lastComment }) => lastComment);

  return (
    <>
      <div className={Css.tableContainer}>
        {!!selectedDocuments.length && (
          <BulkActions
            disabled={fetchingData}
            selectedDocuments={selectedDocuments}
            onCancel={handleBulkActionsCancel}
            onEdit={handleBulkActionsEdit} />
        )}
        <Filters simplifyLayout={simplifyLayout} disabled={fetchingData} />
        {documentsData.length
          ? (
            <Table
              disabled={fetchingData}
              className={classNames(Css.documentsTable, businessUser && Css.businessUser)}
              sortBy={envVars.sortBy}
              sortOrder={envVars.sortOrder}
              onSortChange={handleTableSortChange}>
              <TableRow>
                <TableHead className={Css.checkboxCell}>
                  {!!documentsData.length && (
                    <Checkbox
                      checked={allItemsSelected}
                      disabled={!selectableItemIds.length}
                      indeterminate={!!selectedDocuments.length}
                      onChange={handleSelectAllChange} />
                  )}
                </TableHead>
                <TableHead className={Css.statusCell} accessor="status">{uiTexts.status}</TableHead>
                <TableHead className={Css.typeCell} accessor="type">{uiTexts.type}</TableHead>
                <TableHead className={Css.documentIdCell} accessor="originalDocumentId">{`${uiTexts.document} #`}</TableHead>
                <TableHead className={Css.amountCell} accessor="amount">{uiTexts.amount}</TableHead>
                <TableHead className={Css.placeholderCell} />
                <TableHead className={Css.addressCell} accessor="address">{uiTexts.payee}</TableHead>
                <TableHead show={!simplifyLayout} className={Css.issueDateCell} accessor="issueDate">
                  {uiTexts.issueDate}
                </TableHead>
                <TableHead show={!simplifyLayout} className={Css.descriptionCell}>
                  {uiTexts.description}
                </TableHead>
                <TableHead
                  show={!simplifyLayout}
                  className={classNames(Css.categoriesCell, showCategorySelector && Css.withCategorySelector)}>
                  {`${uiTexts.categories}/${uiTexts.items}`}
                </TableHead>
                <TableHead
                  className={Css.paymentAccountCell}
                  show={!simplifyLayout && (xeroBusiness || zohoBusiness)}
                  accessor="payTo.serviceAccountId">
                  {uiTexts.paid}
                </TableHead>
                <TableHead
                  show={!simplifyLayout && !czCountry && advancedDocumentsWorkflow}
                  className={Css.transactionsCell}>
                  {uiTexts.transactions}
                </TableHead>
                <TableHead className={Css.commentsCell} show={showCommentCell}>{uiTexts.comments}</TableHead>
                <TableHead show={!simplifyLayout} className={Css.uploadedByCell}>
                  {uiTexts.uploaded}
                </TableHead>
                <TableHead className={Css.previewCell} title={uiTexts.preview}>{uiTexts.preview}</TableHead>
                <TableHead className={Css.actionsCell}>{uiTexts.actions}</TableHead>
              </TableRow>
              {documentsData.map((documentData) => {
                const {
                  id: documentId,
                  status: documentStatus,
                  type: documentType,
                  paymentType,
                  createdAt,
                  createdBy,
                  lastExportErrors,
                  recogniseData = {}
                } = documentData;

                const duplicatedDocument = Array.isArray(documentData.duplicatedDocumentsIds)
                    && !!documentData.duplicatedDocumentsIds.length;

                const readyToApprove = documentsReadyToApproveIds.includes(documentId);

                const selected = selectedDocuments.includes(documentId);

                const errorState = recogniseData.status === "error" || lastExportErrors?.length;

                const warningState = readyToApprove && closedBookDateDocumentIds.includes(documentId);

                return (
                  <TableRow
                    key={documentId}
                    className={classNames(
                      Css.tableRow,
                      CommonCss.tableRow,
                      duplicatedDocument && CommonCss.warningRow,
                      readyToApprove && !warningState && CommonCss.positiveRow,
                      errorState && CommonCss.negativeRow,
                      selected && CommonCss.selectedRow,
                      warningState && CommonCss.warningRow
                    )}
                    disabled={fetchingData || lockedDocuments[documentId]}
                    data-id={documentData.id}
                    onClick={handleRowClick}>
                    <TableCell className={Css.checkboxCell} onClick={Utils.handleStopEventPropagation}>
                      <Checkbox
                        checked={selected}
                        disabled={!selectableItemIds.includes(documentId)}
                        data-id={documentId}
                        onChange={handleSelectedChange} />
                    </TableCell>
                    <TableCell className={Css.statusCell}>
                      <StatusCell documentData={documentData} />
                    </TableCell>
                    <TableCell className={Css.typeCell}>
                      <TypeCell documentData={documentData} />
                    </TableCell>
                    <TableCell className={Css.documentIdCell}>
                      <DocumentIdCell documentData={documentData} />
                    </TableCell>
                    <TableCell className={Css.amountCell}>
                      <AmountCell documentData={documentData} />
                    </TableCell>
                    <TableCell className={Css.placeholderCell} />
                    <TableCell className={Css.addressCell}>
                      <AddressCell documentData={documentData} />
                    </TableCell>
                    <TableCell show={!simplifyLayout} className={Css.issueDateCell}>
                      <IssueDateCell documentData={documentData} />
                    </TableCell>
                    <TableCell show={!simplifyLayout} className={Css.descriptionCell}>
                      <LineItemsCell documentData={documentData} />
                    </TableCell>
                    <TableCell
                      show={!simplifyLayout}
                      className={classNames(Css.categoriesCell, showCategorySelector && Css.withCategorySelector)}>
                      <CategoriesCell
                        documentId={documentId}
                        status={documentStatus}
                        type={documentType}
                        paymentType={paymentType}
                        lineItems={documentData.lineItems}
                        onEdit={handleEditDocument} />
                    </TableCell>
                    <TableCell className={Css.paymentAccountCell} show={!simplifyLayout && (xeroBusiness || zohoBusiness)}>
                      <PaymentAccountCell
                        serviceAccountId={documentData.payTo?.serviceAccountId}
                        status={documentData.status}
                        paymentType={documentData.paymentType}
                        paidTransactions={documentData.paidTransactions} />
                    </TableCell>
                    <TableCell
                      show={!simplifyLayout && !czCountry && advancedDocumentsWorkflow}
                      className={Css.transactionsCell}
                      onClick={Utils.handleStopEventPropagation}>
                      <TransactionsCell
                        documentData={documentData}
                        onPairTransactionsLinkClick={onPairTransactionsLinkClick} />
                    </TableCell>
                    <TableCell
                      className={Css.commentsCell}
                      show={showCommentCell}
                      onClick={Utils.handleStopEventPropagation}>
                      <CommentCell documentData={documentData} />
                    </TableCell>
                    <TableCell show={!simplifyLayout} className={Css.uploadedByCell}>
                      <UploadedByCell
                        createdBy={createdBy}
                        createdAt={createdAt} />
                    </TableCell>
                    <TableCell className={Css.previewCell} onClick={Utils.handleStopEventPropagation}>
                      <PreviewCell documentData={documentData} />
                    </TableCell>
                    <TableCell className={Css.actionsCell} onClick={Utils.handleStopEventPropagation}>
                      <ActionsCell
                        documentData={documentData}
                        openDocument={openDocument}
                        refetchDocuments={refetchDocuments}
                        disableApprove={!readyToApprove}
                        onApprove={handleDocumentApprove} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          )
          : <EmptyState refetchDocuments={refetchDocuments} />}
        <div className={Css.footer}>
          {(documentsStats.current > Number(pageSize)) && (
            <Pagination
              disabled={fetchingData}
              className={Css.pagination}
              count={documentsStats.current}
              page={Number(page)}
              pageSize={Number(pageSize)}
              onChange={handlePageChange} />
          )}
          {(documentsStats.current > Constants.TABLE_PAGE_SIZE) && (
            <SelectPageSize
              disabled={fetchingData}
              className={Css.pageSize}
              pageSize={Number(pageSize)}
              onChange={handlePageSizeChange} />
          )}
        </div>
        {!fetchingData && !!documentsData.length && (
          <DocumentsPopup
            ids={documentsReadyToApproveIds.filter((id) => !closedBookDateDocumentIds.includes(id))}
            onSubmit={handlePopupSubmit} />
        )}
      </div>
    </>
  );
};

export default React.memo(DocumentsTable);
